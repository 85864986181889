


















































































































































































































































































	import { Component, Vue } from 'vue-property-decorator'
	import { namespace, Action, State } from 'vuex-class'
	import { Route } from 'vue-router'
	import PageHeader from '@/components/common/PageHeader.vue'
	import { IBreadcrumb } from '@/types/common'
	import { dataURLtoFile, base64toBlob } from '@/utils'
	import { mapState, mapActions } from 'vuex'
	import { IMiniAppItem } from '@/types/common.d.ts'
	type UploadForm = {
		templateId: string
		userDesc: string
		userVersion: string
		piwikSiteId: string
		funDebug: string
		projectId: string
	}

	// const deployment = namespace('deployment')
	// const mpFeatures = namespace('mpFeatures')
	@Component({
		components: {
	    'page-header': PageHeader
	  },
  	methods: {
      ...mapActions([
      	'fetchMiniProgram',
      	'uploadMiniappCode',
      	'submitMiniappAudit',
      	'undoAudit',
      	'rollBackMiniapp',
      	'releaseMiniapp',
      	'generateQRCode',
      	'uploadQRCode',
      	'addMember',
      	'updateVisitStatus',
      	'updateSearchStatus',
      	'updateBreadcrumb'
      ])
    }
	})
	export default class MiniProgramDetail extends Vue {

		fetchMiniProgram: (id: string) => any
		uploadMiniappCode: (form: any) => any
		submitMiniappAudit: (projectId: string) => any
		undoAudit: (projectId: string) => any
		rollBackMiniapp: (projectId: string) => any
		releaseMiniapp: (projectId: string) => any
		generateQRCode: (params: any) => any
		uploadQRCode: (payload: any) => any
		addMember: (params: {wechatId: string, projectId: string}) => any
		updateVisitStatus: (params: {status: string, projectId: string}) => any
		updateSearchStatus: (params: {status: number, projectId: string}) => any
		updateBreadcrumb: (params: Array<IBreadcrumb>) => any
		public isLoading: boolean = true
		private memberForm: any = {
			wechatId: ''
		}
		private showBlockModal: boolean = false

		private miniProgram: any = null
		private showMemberModal: boolean = false
		private uploadForm: UploadForm = {
			templateId: '',
			userDesc: '',
			userVersion: '',
			piwikSiteId: '',
			funDebug: '',
			projectId: ''
		}

		get deploysLength () {
			return this.miniProgram && this.miniProgram.deploys.length
		}

		get projectId () {
			return this.miniProgram && this.miniProgram.project.id
		}

		get canBeReleased (): boolean|undefined {
			if (this.miniProgram && this.miniProgram.deploys.length > 0) {
				return this.miniProgram.deploys[this.deploysLength - 1].auditInfo.status === 0
			}
		}

		public tapAddMember () {
			this.showMemberModal = true
		}

		submitMemberForm () {
			this.addMember({wechatId: this.memberForm.wechatId, projectId: this.projectId}).then((res: any) => {
				this.closeModal()
				this.fetchMiniProgram(this.$route.params.id)
			})
		}
		
		public upload () {
			this.uploadForm.projectId = this.miniProgram.project.id
			this.uploadMiniappCode(this.uploadForm)
		}

		public submit () {
			this.submitMiniappAudit(this.miniProgram.project.id)
		}

		public async tapRelease () {
			await this.releaseMiniapp(this.miniProgram.project.id)
		}

		public async tapUndoAudit () {
			this.undoAudit(this.miniProgram.project.id).then((res: any) => {
				if (res && res.errcode == 0) {
					this.$toast.success('Undo successfully', {label: 'Wooooo'})
				} else {
					this.$toast.error(res.errmsg, {label: 'Undo failed'})
				}
			})
		}

		public async tapRollBack () {
			await this.rollBackMiniapp(this.miniProgram.project.id)
			this.$toast.success('Roll back successfully', {label: 'Hahaha'})
		}

		public changeVisitStatus (val: boolean|string) {
			console.log(">>> changeVisitStatus", val)
			if (val) {
				this.$modal({
					content: this.$t('UI.BLOCK_MP_WARNING'),
					header: 'Block Mini-program',
				  onCertify: () => {
				  	this.updateVisitStatus({projectId: this.miniProgram.project.id, status: val ? 'close' : 'open'}).then((res: any) => {
				  		this.miniProgram.info.MiniProgramInfo.visit_status = val ? 1 : 0
				  		this.$toast.success(`Already ${val ? 'blocked' : 'unblocked'} this Mini-Program`, {decuration: 2000})
				  	}).catch((err: any) => {
				  		this.$toast.error(err.error.message)
				  	})
				  }
				})
			} else {
				this.miniProgram.info.MiniProgramInfo.visit_status = val ? 1 : 0
				this.updateVisitStatus({projectId: this.miniProgram.project.id, status: val ? 'close' : 'open'}).then((res: any) => {
					this.$toast.success(`Already ${val ? 'blocked' : 'unblocked'} this Mini-Program`)
					this.miniProgram.info.MiniProgramInfo.visit_status = val ? 1 : 0
				}).catch((err: any) => {
		  		this.$toast.error(err.error.message)
		  	})
			}
		}

		public changeSearchStatus (val: boolean) {
			this.updateSearchStatus({projectId: this.miniProgram.project.id, status: val ? 0 : 1}).then((res: any) => {
				if (res.errcode == 0) {
					this.$set(this.miniProgram.info.MiniProgramInfo, 'search_status', val ? 0 : 1)
				} else {
					this.$toast.error(res.errmsg)
				}
			})
		}

		closeModal () {
			this.showMemberModal = false
			this.memberForm.wechatId = ''
		}
		
		async tapGenerateQR () {
			const params: any = {path: 'pages/index/main', projectId: this.miniProgram.project.id}
			this.generateQRCode(params).then((res: any) => {
				const qrcode: string = `data:image/jpeg;base64,${res.QRCode.imageBase64String}`
				this.miniProgram.qrCodeUrl = qrcode
				const file = base64toBlob(res.QRCode.imageBase64String, 'image/jpeg')
				const form = new FormData()
				form.append('file', file)
				this.$store.dispatch('uploadQRCode', {projectId: this.miniProgram.project.id, file: form})
			}).catch((err: any) => {
				this.$toast.error(err.error.message)
			})
		}

		async mounted () {
			try {
				const data = await this.fetchMiniProgram(this.$route.params.id)
				this.miniProgram = data
				const breadcrumb = [
					{label: 'Mini-Program Management'},
					{label: 'Mini-Program List', url: {name: 'mini-programs'}},
					{label: String(this.miniProgram.project.shopName)}
				]
				this.updateBreadcrumb(breadcrumb)
			} catch (err) {
				this.$toast.error(err.message)
			} finally {
				this.isLoading = false
			}
		}
	}
