export function dataURLtoFile(dataurl: string, filename: string) {
  let arr = dataurl.split(',')
  if (arr.length == 0) {
  	return null
  }
	let mime = arr[0]!.match(/:(.*?);/)[1],
	    bstr = atob(arr[1]), 
	    n = bstr.length, 
	    u8arr = new Uint8Array(n)
  while( n-- ){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

export function base64toBlob(base64: string, contentType: string) {
  const byteCharacters = atob(base64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}