













import { Component, Vue, Prop } from 'vue-property-decorator'
import { IBreadcrumb } from '@/types/common'
import { State } from 'vuex-class'
@Component({
	name: 'page-header',
})
export default class PageHeader extends Vue {
	@State(state => state.breadcrumb) breadcrumb: any

}
